import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';

import { connect } from 'react-redux';
import asPage from 'components/Page';

import { useSelector } from 'react-redux';
import {
    useFirebase, isLoaded, isEmpty,
    firestoreConnect,
} from 'react-redux-firebase';
import 'firebase/firestore';

import {
    Redirect
} from 'react-router-dom';

import CollectionsList from './components/Collections';

import { AddButton } from './components/Common/Button.style';
import { ActionsList } from './components/Common/Lists.style';
import { Wrapper, Container } from './components/Common/Layout.style';


const Collections = ({ collections }) => {
    const [emptyCollections, setNewEmptyCollections] = useState([]);

    const firebase = useFirebase();
    const firebaseAppAuth = firebase.auth();

    const auth = useSelector(state => state.firebase.auth)

    const providers = {
        googleProvider: new firebase.auth.GoogleAuthProvider(),
    };

    if (!isLoaded(auth)) {
        return <div>loading...</div>;
    }

    if (isEmpty(auth)) {
        return (
            <Redirect push to={'/'} />
        )
    }

    const hasCollections = collections.length > 0 || emptyCollections.length > 0;

    return (
        <div>
            {hasCollections && (
                <CollectionsList
                    collections={collections}
                    emptyCollections={emptyCollections}
                    collectionsCount={collections.length}
                    onDeleteCback={(collection) => {
                        const tmpEmptyCollections = emptyCollections.filter(p => p.id != collection.id);
                        setNewEmptyCollections(tmpEmptyCollections);
                    }}
                    addNewCback={(collection) => {
                        const tmpEmptyCollections = emptyCollections.filter(p => p.id != collection.id);
                        setNewEmptyCollections(tmpEmptyCollections);
                    }}
                />
            )}

            <Wrapper noMargin>
                <Container noShadow>
                    <ActionsList>
                        <AddButton
                            type="button"
                            onClick={() => setNewEmptyCollections([
                                ...emptyCollections,
                                {
                                    id: '_' + Math.random().toString(36).substr(2, 9),
                                    isNew: true,
                                    order: collections.length + emptyCollections.length,
                                },
                            ])}
                        >
                            Add New Collection
                        </AddButton>
                    </ActionsList>
                </Container>
            </Wrapper>
        </div>
    );
};

const mapStateToProps = state => ({
    collections: state.firestore.ordered.collections || [],
});

export default firestoreConnect(() => [{
	collection: 'collections',
	orderBy: [
		[ 'startDate', 'desc' ],
	],
}])(asPage(connect(mapStateToProps, null)(Collections), 'Collections', true, true));

