import React, { Component, Fragment, PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import GA from 'react-ga';

import { useTransition, animated } from 'react-spring';

import ThemeProvider from '../../components/ThemeProvider';
import theme from '../../themes/default';
import GlobalStyle from '../../themes/global';

import posed, { PoseGroup } from 'react-pose';

import { renderChildRoutes } from '../../routes';

import { Main, Page } from './App.style';


const App = ({ location, route }) => {
    const transitions = useTransition(location, location => location.pathname, {
        from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    });

    const { key } = location || {};

    return (
        <Fragment>
            <Helmet titleTemplate="Coder Collection – coder.si">
                <title>Tjasa Rijavec</title>
                <meta property="og:url"                content="https://coder.si/" />
                <meta property="og:type"               content="website" />

                <meta name="generator" content="Coder Engine" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>

            <ThemeProvider theme={theme}>
                <Fragment>
                    <GlobalStyle whiteColor />
                    <Main>
                        {renderChildRoutes({ route })}
                    </Main>
                </Fragment>
            </ThemeProvider>
        </Fragment>
    );
};

export default App;
