import React from 'react';

import { useDispatch } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

import {
    DropContainer, GalleryContainer,
    DragZone, Actions, AddButton,
} from './Items.style';

import { connect } from 'react-redux';
import { getAction, ActionTypes } from '../../../../actions';

import useWindowSize from '../../../../utils/useWindowSize';
import theme from '../../../../themes/default';

import Gallery from 'components/Gallery';

import { useDropzone } from 'react-dropzone';

import { uploadFiles, uploadFile, UPLOAD_STATE } from '../../../../utils/uploader';
import { getImagePreview, ImageTools } from '../../../../utils/images';

import { Wrapper } from '../../../Collections/components/Common/Layout.style';

const sizeOptions = [{
    name: 'thumbnail',
    size: 600,
    quality: 0.75,
}, {
    name: 'full',
    size: 1200,
    quality: 0.87,
}];

const Items = ({ tmpItems, items, collection, collectionId, blocksCount, onDeleteCback }) => {
    const dispatch = useDispatch();
    let collectionName = '';
    try {
        collectionName = collection.name.toLowerCase();
    } catch (e) {}

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < theme.screenSize.mediumTablet;
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        // accept: 'image/*, video/*',
        onDrop: acceptedFiles => {
            const droppedFiles = acceptedFiles.map(file => Object.assign(file, {
                percent: 0,
                tmpId: Math.random().toString(36).substr(2, 5),
                preview: URL.createObjectURL(file),
                width: 0,
                height: 0,
            }));

            for(let a = 0; a < droppedFiles.length; a += 1) {
                const item = droppedFiles[a];

                dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), id: item.tmpId, data: item });
                // uploadFile({
                //     file: item,
                //     index: a,
                //     stateChangeCback: (type, file, index, data) => {
                //         dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), id: file.tmpId, data });
                //     },
                // });

                for (let b = 0; b < sizeOptions.length; b += 1) {
                    const { size, name, quality } = sizeOptions[b];
                    // console.log('size', size, item.type);

                    ImageTools.rotate(item, (blob, width, height) => {
                        // const newFile = new File([blob], `${size}_${item.name}`, {
                        //     type: item.type,
                        //     tmpId: item.tmpId,
                        // });
                        const newFile = Object.assign(new File([blob], `${item.name}`, {
                            type: item.type,
                        }), {
                            tmpId: item.tmpId,
                        });
                        // console.log('file.,...', {item, size, blob, width, height, newFile});

                        uploadFile({
                            file: newFile,
                            folder: `${collectionName}/s${size}`,
                            width,
                            height,
                            type: item.type,
                            stateChangeCback: (type, file, index, data) => {
                                switch (type) {
                                    case UPLOAD_STATE.Progress:
                                        if (name === 'full') {
                                            const updatedData = {
                                                ...data,
                                                tmpId: item.tmpId,
                                                width: parseInt(Math.floor(width + 0.5)),
                                                height: parseInt(Math.floor(height + 0.5)),
                                            };
                                            dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), id: file.tmpId, data: updatedData });
                                        }
                                        break;
                                    case UPLOAD_STATE.Done:
                                        const updatedData = {};
                                        if (name === 'full') {
                                            updatedData.url = data.url;
                                            updatedData.fileType = data.fileType;
                                            updatedData.publicId = data.public_id;
                                            updatedData.width = parseInt(Math.floor(width + 0.5));
                                            updatedData.height = parseInt(Math.floor(height + 0.5));
                                        }
                                        else {
                                            updatedData[name] = {
                                                url: data.url,
                                                fileType: data.fileType,
                                                publicId: data.public_id,
                                                width: parseInt(Math.floor(width + 0.5)),
                                                height: parseInt(Math.floor(height + 0.5)),
                                            };
                                        }
                                        dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), id: file.tmpId, data: updatedData });
                                    break;
                                }
                            },
                        });
                    }, item.type, quality, size);
                }


                // ImageTools.rotate(item,  (rotatedImage) => {
                //     console.log('original', URL.createObjectURL(item))
                //     console.log('rotated', URL.createObjectURL(rotatedImage))
                //     ImageTools.resize(rotatedImage, {
                //         width: 320, // maximum width
                //         height: 240 // maximum height
                //     }, (blob, didItResize) => {
                //         const newItem = {
                //             ...item,
                //             preview: URL.createObjectURL(blob),
                //         };
                //         console.log('resized...', didItResize, URL.createObjectURL(blob))
                //         dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), id: item.tmpId, data: newItem });

                //         // didItResize will be true if it managed to resize it, otherwise false (and will return the original file as 'blob')
                //         // document.getElementById('preview').src = window.URL.createObjectURL(blob);
                //         // you can also now upload this blob using an XHR.
                //     });
                // })
            }

            // uploadFiles({
            //     files: droppedFiles,
            //     stateChangeCback: (type, file, index, data) => {
            //         // console.log('UPDATE', tmpItems.length, type, file.tmpId, data);
            //         dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), id: file.tmpId, data });

            //         // switch (type) {
            //         //     case UPLOAD_STATE.Size:
            //         //         dispatch({ type: 'UPDATE_UPLOAD', id: item.tmpId, data });

            //         //         // setTmpItems(tmpItems.map((item) => {
            //         //         //     console.log('item.tmpId', item.tmpId, file.tmpId)
            //         //         //     if (item.tmpId === file.tmpId) return ({
            //         //         //         ...item,
            //         //         //         ...data,
            //         //         //     });
            //         //         //     return item;
            //         //         // }));
            //         //         break;
            //         //     case UPLOAD_STATE.Progress:
            //         //         // setImageOpacityState(data.percent / 150.0);
            //         //         dispatch({ type: 'UPDATE_UPLOAD', id: item.tmpId, data });

            //         //         setTmpItems(tmpItems.map((item) => {
            //         //             if (item.tmpId === file.tmpId) return ({
            //         //                 ...item,
            //         //                 percent: data.percent,
            //         //             });
            //         //             return item;
            //         //         }));
            //         //         break;
            //         //     case UPLOAD_STATE.Done:
            //         //         // remove from tmp state
            //         //         // setImageOpacityState(1);
            //         //         break;
            //         // }
            //     },
            // })
            // // .then(data => {
            // //     // handleChange(data, 'image');
            // //     console.log('handleChange', data);
            // // });
        },
    });

    const saveItem = (itemsRef, data) => new Promise((resolve, reject) => {
        itemsRef.add(data)
        .then((docRef) => {
            // console.log('Document successfully created');
            resolve(docRef.id);
        })
        // .catch((error) => {
        //     console.error('Error creating document: ', error);
        // });
    });

    const db = useFirestore();
    const handleSubmit = async (event) => {
        event.preventDefault();

        const itemsOrder = [
            ...collection.itemsOrder || [],
        ];

        const itemsRef = db.collection('collections').doc(collectionId).collection('items');
        for (let a = 0; a < tmpItems.length; a += 1) {
            const item = tmpItems[a];

            const itemId = await saveItem(itemsRef, {
                type: item.fileType,
                publicId: item.publicId,
                width: item.width,
                height: item.height,
                thumbnail: {
                    publicId: item.thumbnail.publicId,
                    width: item.thumbnail.width,
                    height: item.thumbnail.height,
                },
                // width: item.width,
                // height: item.height,
                // url: item.url,
                // fileType: item.fileType,
                // publicId: item.public_id,
                // filename: item.original_filename,
                // type: item.type,
                // path: item.path,
                // ['600']: {
                //     url: item['600'].url,
                //     fileType: item['600'].fileType,
                //     publicId: item['600'].public_id,
                //     width: item['600'].width,
                //     height: item['600'].height,
                // },
                // ['1200']: {
                //     url: item['1200'].url,
                //     fileType: item['1200'].fileType,
                //     publicId: item['1200'].public_id,
                //     width: item['1200'].width,
                //     height: item['1200'].height,
                // },
            });
            dispatch({ type: getAction('REMOVE_UPLOAD', ActionTypes.SET), id: item.tmpId });
            itemsOrder.push(itemId);
        }

        const collectionRef = db.collection('collections');
        collectionRef.doc(collectionId).update({
            itemsOrder,
        })
        // .then(function(docRef) {
        //     console.log('Document successfully updated');
        // })
        // .catch(function(error) {
        //     console.error('Error updating document: ', error);
        // });
    }

    const filesConfig = [];
    const savedTmpIds = new Set();

    items.forEach(item => {
        savedTmpIds.add(item.tmpId);
        filesConfig.push({
            src: getImagePreview(item),
            thumbnail: getImagePreview(item.thumbnail),
            thumbnailWidth: item.thumbnail.width,
            thumbnailHeight: item.thumbnail.height,
            opacity: 1,
        });
    });

    tmpItems.filter(item => item.width > 0 && !savedTmpIds.has(item.tmpId)).forEach(item => {
        filesConfig.push({
            src: item.preview,
            thumbnail: item.preview,
            thumbnailWidth: item.width,
            thumbnailHeight: item.height,
            caption: item.percent < 100 ? `Uploading... ${item.percent}%` : null,
            thumbnailCaption: item.percent < 100 ? `Uploading... ${item.percent}%` : null,
            opacity: item.percent / 100.0,
            isUploading: item.percent < 100,
        });
    });

    return (
        <Wrapper>
            <DropContainer>
                <Actions>
                    <DragZone {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop the image here, or click to select one</p>
                    </DragZone>
                    <AddButton type="button" onClick={(event) => handleSubmit(event)}>
                        Save
                    </AddButton>
                </Actions>
            </DropContainer>
            <GalleryContainer>
                <Gallery
                    images={filesConfig}
                    enableImageSelection={false}
                />
            </GalleryContainer>
        </Wrapper>
    );
}


const mapStateToProps = (state) => {
    return {
        tmpItems: state.uploader,
    };
};

export default connect(mapStateToProps, null)(Items);
