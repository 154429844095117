import { put, takeEvery, all, call, take } from 'redux-saga/effects';

import * as pageActions from 'components/Page/actions';

import * as firebase from 'firebase/app';
import { getFirebase } from 'react-redux-firebase';

export function* loadCollections({ params }) {
}

function* load(action) {
    yield loadCollections(action);
    yield put(pageActions.loaded.create('Collections'));
}

function* preload(action) {
    yield loadCollections(action);
    yield put(pageActions.preloaded.create('Collections'));
}

function* createNewCollection(action) {
    const { name } = action;

    const fb = getFirebase();
    const db = fb.firestore();

    var docData = {
        name,
        order: 1,
        objectExample: {
            foo: 'foo',
            bar: {
                a: 1,
                b: true,
            },
        },
    };

    db.collection("collections").add(docData)
    .then(function(docRef) {
        console.log("Document written with ID: ", docRef.id, docRef);
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
}

export default function* pageSaga() {
    yield all([
        takeEvery(action => action.type === pageActions.preload.ACTION && action.pageId === 'Collections', preload),
        takeEvery(action => action.type === pageActions.load.ACTION && action.pageId === 'Collections', load),
        takeEvery(action => action.type === 'new-collection', createNewCollection),
    ]);
}
