import styled from 'styled-components';

import { Container } from '../../../Collections/components/Common/Layout.style';
import { Button } from '../../../Collections/components/Common/Button.style';
import { a } from 'react-spring';

const Actions = styled.div`
    display: flex;
`;

const DropContainer = styled(Container)`
    position: fixed;
    width: 100%;
    height: 150px;
    z-index: 1;
    box-shadow: 0 5px 20px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15);
`;

const GalleryContainer = styled(Container)`
    margin-top: 175px;
    box-shadow: none;
`;

const AddButton = styled(Button)`
    background-color: #1aa358;
    box-shadow: none;
    color: #fff;

    height: 48px;
    align-self: center;
    margin-right: 16px;
    width: 120px;

    &:hover {
        background-color: #1a8358;
        box-shadow: none;
    }
`;

const DragZone = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 150px;
    padding: 20px;
    align-content: center;
    justify-content: center;
`;

const ThumbsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
`;

const Thumb = styled(a.div)`
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    // width: 100px;
    // height: 100px;
    padding: 4px;
    box-sizing: border-box;
    opacity: 0;

    width: 100%;
    height: 100%;
`;

const ThumbInner = styled.div`
    display: flex;
    min-width: 0px;
    overflow: hidden;

    width: 100%;
    height: 100%;
`;

const ThumbPreview = styled.div`
    display: block;
    width: 60px;
`;

const Img = styled.img`
    display: block;
    // width: auto;
    // height: 100%;

    width: 100%;
    height: 100%;
`;

export {
    DropContainer, GalleryContainer,
    DragZone, Actions, AddButton,
    ThumbsContainer, Thumb, ThumbInner, Img,
};
