
import React from 'react';

import useWindowSize from '../../../../utils/useWindowSize';
import theme from '../../../../themes/default';

import {
    Table, THead, THeadTH, TBody,
} from './Collections.style';

import Collection from '../Collection';

import { Wrapper, Container } from '../Common/Layout.style';

const Collections = ({ collections, emptyCollections, collectionsCount, addNewCback, onDeleteCback }) => {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < theme.screenSize.mediumTablet;

    return (
        <Wrapper>
            <Container>
                <Table>
                    <colgroup>
                        <col width={isMobile ? '50%' : '100%'}></col>
                        {!isMobile && (<col width="50%"></col>)}
                        <col width="85"></col>
                    </colgroup>
                    <THead>
                        <tr>
                            <THeadTH>Collection</THeadTH>
                            {!isMobile && (<THeadTH>Status</THeadTH>)}
                            <THeadTH>&nbsp;</THeadTH>
                        </tr>
                    </THead>
                    {collections.map((collection, i) => (
                        <Collection
                            key={collection.id}
                            collection={collection}
                            collections={collections}
                            itemIdx={i}
                            collectionsCount={collectionsCount}
                            addNewCback={addNewCback}
                            onDeleteCback={onDeleteCback}
                        />
                    ))}
                    {emptyCollections.map(collection => (
                        <Collection
                            key={collection.id}
                            collection={collection}
                            collectionsCount={collectionsCount}
                            addNewCback={addNewCback}
                            onDeleteCback={onDeleteCback}
                        />
                    ))}
                </Table>
            </Container>
        </Wrapper>
    );
}

export default Collections;
