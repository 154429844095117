
import React from 'react';

import {
    Wrapper, AddIcon,
} from './Facts.style';


import Fact from '../Fact';

const Facts = ({ facts, addNewFact, onDeleteFact, onChangeFact }) => {

    return (
        <Wrapper>
            {facts.map((fact, i) => (
                <Fact
                    key={`fact-${i}`}
                    index={i}
                    {...fact}
                    onChange={onChangeFact}
                    onDelete={onDeleteFact}
                />
            ))}
            <AddIcon fontSize="large" onClick={addNewFact} />
        </Wrapper>
    );
}

export default Facts;
