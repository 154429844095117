import React, { Fragment, useState } from 'react';
import { getParams } from 'components/Page/selectors';

import { useSelector } from 'react-redux'
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase';

import { connect } from 'react-redux';
import asPage from 'components/Page';

import { firestoreConnect } from 'react-redux-firebase';

// import Items from './components/Items';
import Items from './components/Items2';

import { AddButton } from '../Collections/components/Common/Button.style';
import { ActionsList } from '../Collections/components/Common/Lists.style';
import { ContentRow } from '../Collections/components/Common/Row.style';
import { Wrapper, Container } from '../Collections/components/Common/Layout.style';

const Collection = ({ params, itemId, collection, items }) => {
    const [emptyItems, setNewEmptyItems] = useState([]);

    const firebase = useFirebase();
    const firebaseAppAuth = firebase.auth();
    const auth = useSelector(state => state.firebase.auth)

    if (!isLoaded(auth)) {
        return <div>loading...</div>;
    }

    if (isEmpty(auth)) {
        return (
            <Redirect push to={/login/} />
        )
    }

    const hasItems = items.length > 0 || emptyItems.length > 0;
    const itemsOrdered = items.sort(function (a, b) { return (a.order > b.order) ? 1 : (a.order < b.order) ? -1 : 0; });

    return (
        <div>
            <Items
                collection={collection}
                collectionId={itemId}
                items={itemsOrdered}
            />
            {/* <Wrapper noMargin>
                <Container noShadow>
                    <ActionsList>
                        <AddButton
                            type="button"
                            onClick={() => setNewEmptyItems([
                                ...emptyItems,
                                {
                                    id: '_' + Math.random().toString(36).substr(2, 9),
                                    isNew: true,
                                    order: null,
                                },
                            ])}
                        >
                            Add New Item
                        </AddButton>
                    </ActionsList>
                </Container>
            </Wrapper>
            {hasItems && (
                <Items
                    items={itemsOrdered}
                    itemId={itemId}
                    emptyItems={emptyItems}
                    blocksCount={items.length}
                    onDeleteCback={(block) => {
                        const tmpEmptyItems = emptyItems.filter(b => b.id != block.id);
                        setNewEmptyItems(tmpEmptyItems);
                    }}
                    addNewCback={(block) => {
                        const tmpEmptyItems = emptyItems.filter(b => b.id != block.id);
                        setNewEmptyItems(tmpEmptyItems);
                    }}
                />
            )} */}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const params = getParams(state, 'Collection');
    const { id } = params;

    const collections = state.firestore.data.collections || {};
    const collection = collections[id] || {};
    // const items = state.firestore.ordered[`${id}-items`] || [];

    const unorderedItems = state.firestore.data[`${id}-items`] || {};
    const items = [];
    (collection.itemsOrder || []).forEach(tmpId => {
        if (unorderedItems[tmpId]) {
            items.push(unorderedItems[tmpId]);
        }
    });
    // console.log('items', items)
    return {
        params,
        collection: collections[id] || {},
        itemId: id,
        items,
    }
};

export default firestoreConnect((state) => {
    const { id } = state.match.params;
    return [{
        collection: 'collections',
        doc: id,
}, {
    collection: 'collections',
    doc: id,
    subcollections: [{
        collection: 'items',
        // orderBy: [
        //     [ 'order', 'desc' ],
        // ],
    }],
    storeAs: `${id}-items`
}]})(asPage(connect(mapStateToProps, null)(Collection), 'Collection', true, true));

