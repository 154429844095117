import { all, fork } from 'redux-saga/effects';

import admin from './Collections/sagas';
import collection from './Collection/sagas';
import info from './Collection/sagas';
import pages from './Pages/sagas';

export default function* () {
    yield all([fork(admin), fork(collection), fork(pages)]);
}
