const config = {
    apiKey: "AIzaSyB2Q7aS-D1KSAUFTik3fgMY9OEaQ7cY6b0",
    authDomain: "codersi-gallery.firebaseapp.com",
    databaseURL: "https://codersi-gallery.firebaseio.com",
    projectId: "codersi-gallery",
    storageBucket: "codersi-gallery.appspot.com",
    messagingSenderId: "1065479709867",
    appId: "1:1065479709867:web:ee505c8e976bdd3714c3ee",
    measurementId: "G-HXFBZHK7WR"
};

export default config;
