
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSpring } from 'react-spring'

import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { useFirestore } from 'react-redux-firebase';

import Link from 'components/Link';

import {
    TBody, TBodyTR, TBodyTD, TBodyTDActions,
    CellWrapper, LayoutRow, EnabledLabel,
    DetailContentTR, DetailContentTD,
    DetailsContentWrapper, DetailsContentCard,
    EditCard, Form, FieldSet,
    SwitchLabel,
    EditIcon, DeleteIcon,
} from './Page.style';

import Facts from '../Facts';

import {
    ThumbsContainer, Thumb, ThumbInner, Img,
} from '../../../Collection/components/Section/Section.style';

import Switch from '../Switch';

import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import { CancelButton, SaveButton } from '../Common/Button.style';
import { InputLabel, InputField } from '../Common/Input.style';
import { ActionsList } from '../Common/Lists.style';
import { ContentRow } from '../Common/Row.style';


import { UPLOAD_STATE, uploadFile } from '../../../../utils/uploader';
import { getImagePreview } from '../../../../utils/images';


import ReactDynamicImport from "react-dynamic-import";
const loader = () => import(`../Wysiwyg`);
const Editor = ReactDynamicImport({ loader });


const Page = ({ page, pagesCount, addNewCback, onDeleteCback }) => {
    const dispatch = useDispatch();
    const [showDetails, toggleDetails] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [image, setImage] = useState(page.image || null);
    const [enabled, setEnabled] = useState(page.enabled || false);
    const [name, setName] = useState(page.name || '');
    const [info, setInfo] = useState(page.info || '');
    const [year, setYear] = useState(page.year || '');
    const [facts, setFacts] = useState(page.facts || []);
    // const [editorState, setEditorState] = useState(EditorState.createEmpty());

    // const content = '<p>sdf&nbsp;</p><p><span style="color: rgb(26,188,156);"><strong>asdfa</strong></span>&nbsp;</p><p style="text-align:center;"><em>sdfa</em></p>';
    // const contentState = ContentState.createFromBlockArray(convertFromHTML(content))

    let contentState = EditorState.createEmpty();
    if (page.description) {
        const rawContent = convertFromRaw(JSON.parse(page.description.raw));
        contentState = EditorState.createWithContent(rawContent);
    }
    const [editorState, setEditorState] = useState(contentState);
    const [openSuccess, setOpenSuccess] = useState(false);


    const initialFiles = [];
    if (image) {
        initialFiles.push({
            name: image.public_id,
            preview: `https://res.cloudinary.com/codersi/image/upload/f_auto,c_scale,w_300/v${image.version}/${image.public_id}.jpg`,
            // preview: `https://res.cloudinary.com/codersi/image/upload/f_auto/v${image.version}/${image.public_id}.jpg`,
            // https://res.cloudinary.com/codersi/image/upload/v1579303328/el3tuurrrwwbi3odsqtr.png
        });
    }
    const [files, setFiles] = useState(initialFiles);


    const db = useFirestore();

    const handleNewFact = () => {
        const newFacts = [...facts];
        newFacts.push({
            name: '',
            altname: '',
            value: '',
        });
        setFacts(newFacts);
    };

    const handleDeleteFact = (index) => {
        const newFacts = [...facts];
        newFacts.splice(index, 1);
        setFacts(newFacts);
    };

    const handleChangeFact = (event, index, field) => {
        const newFacts = [...facts];
        newFacts[index][field] = event.target.value;
        setFacts(newFacts);
    };


    const handleChange = (event, type) => {
        switch(type) {
            case 'name':
                setName(event.target.value);
                break;
            case 'info':
                setInfo(event.target.value);
                break;
            case 'year':
                setYear(event.target.value);
                break;
            case 'image':
                setImage(event);
                break;
            default:
                break;
        }
    };

    const onDelete = (event) => {
        event.preventDefault();
        const confirmed = confirm('Are you sure to do this?');
        if (confirmed) {
            handleDelete();
        }
    };

    const handleDelete = () => {
        if (page.isNew) {
            onDeleteCback(page);
        }
        else {
            db.collection('pages').doc(page.id).delete().then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // setOpenSuccess(true);
        // return;

        const descriptionRaw = convertToRaw(editorState.getCurrentContent());

        const data = {
            enabled,
            name,
            info,
            year,
            image,
            facts,
            description: {
                raw: JSON.stringify(descriptionRaw),
                html: draftToHtml(descriptionRaw),
            },
        };

        const collection = db.collection('pages');
        if (page.isNew) {
            addNewCback(page);

            collection.add(data)
            .then(function(docRef) {
                console.log('Document successfully created');
            })
            .catch(function(error) {
                console.error('Error creating document: ', error);
            });
        }
        else {
            collection.doc(page.id).update(data)
            .then(function(docRef) {
                console.log('Document successfully updated');
                toggleDetails(false);
            })
            .catch(function(error) {
                console.error('Error updating document: ', error);
            });
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            const filteredFiles = [acceptedFiles[0]];
            setFiles(filteredFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

            filteredFiles.map(file => {
                uploadFile({
                    file,
                    stateChangeCback: (type, file, index, data) => {
                        switch (type) {
                            case UPLOAD_STATE.Progress:
                                setImageOpacityState(data.percent / 150.0);
                                break;
                            case UPLOAD_STATE.Done:
                                setImageOpacityState(1);
                                break;
                        }
                    },
                })
                .then(data => {
                    handleChange(data, 'image');
                });
            });
        },
    });

    const thumbs = files.map(file => (
        <Thumb key={file.name} style={{
            opacity: imageOpacity,
        }}>
            <ThumbInner>
                <Img src={file.preview} />
            </ThumbInner>
        </Thumb>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <TBody key={page.id}>
            <TBodyTR onClick={() => toggleDetails(!showDetails)}>
                <TBodyTD>
                    <CellWrapper>
                        <LayoutRow>{page.name}</LayoutRow>
                    </CellWrapper>
                </TBodyTD>
                <TBodyTD>
                    <CellWrapper>
                        <EnabledLabel enabled={enabled}>{enabled ? 'Enabled' : 'Disabled'}</EnabledLabel>
                    </CellWrapper>
                </TBodyTD>
                <TBodyTDActions isVisible={showDetails}>
                    <CellWrapper>
                        {!page.isNew && (
                            <Link to={`/page/${page.id}/`}>
                                <EditIcon />
                            </Link>
                        )}
                        <DeleteIcon onClick={onDelete} />
                    </CellWrapper>
                </TBodyTDActions>
            </TBodyTR>
            {showDetails && (
                <DetailContentTR>
                    <DetailContentTD colSpan="3">
                        <DetailsContentWrapper>
                            {/* <Snackbar open={openSuccess} autoHideDuration={6000} onClose={setOpenSuccess}>
                                <Alert elevation={6} variant="filled" onClose={setOpenSuccess} severity="success">
                                    This is a success message!
                                </Alert>
                            </Snackbar> */}
                            <EditCard>
                                <Form onSubmit={handleSubmit}>
                                    <FieldSet>
                                        <ContentRow first right center>
                                            <Switch enabled={enabled} onToggle={setEnabled} />
                                            <SwitchLabel>Enable</SwitchLabel>
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Name</InputLabel>
                                            <InputField
                                                type="text"
                                                name="name"
                                                autocomplete="off"
                                                spellcheck="false"
                                                value={name}
                                                onChange={(event) => handleChange(event, 'name')}
                                            />
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Info</InputLabel>
                                            <InputField
                                                type="text"
                                                name="info"
                                                autocomplete="off"
                                                spellcheck="false"
                                                value={info}
                                                onChange={(event) => handleChange(event, 'info')}
                                            />
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Year</InputLabel>
                                            <InputField
                                                type="text"
                                                name="year"
                                                autocomplete="off"
                                                spellcheck="false"
                                                value={year}
                                                onChange={(event) => handleChange(event, 'year')}
                                            />
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Description</InputLabel>
                                            <Editor
                                                editorState={editorState}
                                                // wrapperClassName="demo-wrapper"
                                                // editorClassName="demo-editor"
                                                onStateChange={(state) => setEditorState(state)}
                                            />
                                        </ContentRow>
                                        <ContentRow>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop the image here, or click to select one</p>
                                            </div>
                                            <ThumbsContainer>
                                                {thumbs}
                                            </ThumbsContainer>
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Facts</InputLabel>
                                            <Facts
                                                facts={facts}
                                                addNewFact={handleNewFact}
                                                onDeleteFact={handleDeleteFact}
                                                onChangeFact={handleChangeFact}
                                            />
                                        </ContentRow>
                                        <ActionsList>
                                            <CancelButton type="button" onClick={() => toggleDetails(false)}>Cancel</CancelButton>
                                            <SaveButton type="submit">Save</SaveButton>
                                        </ActionsList>
                                    </FieldSet>
                                </Form>
                            </EditCard>
                        </DetailsContentWrapper>
                        <DetailsContentCard></DetailsContentCard>
                    </DetailContentTD>
                </DetailContentTR>
            )}
        </TBody>
    );
}

export default Page;
