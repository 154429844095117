
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSpring } from 'react-spring'

import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import useWindowSize from '../../../../utils/useWindowSize';
import theme from '../../../../themes/default';

import { useFirestore } from 'react-redux-firebase';

import Link from 'components/Link';

import { UPLOAD_STATE, uploadFile } from '../../../../utils/uploader';
import { getImagePreview, ImageTools } from '../../../../utils/images';

import {
    TBody, TBodyTR, TBodyTD, TBodyTDActions,
    CellWrapper, ActionsCellWrapper, LayoutRow, EnabledLabel,
    DetailContentTR, DetailContentTD,
    DetailsContentWrapper, DetailsContentCard,
    EditCard, Form, FieldSet,
    SwitchLabel,
    IconContainer,
    EditIcon, DeleteIcon, MoveUpIcon, MoveDownIcon,
} from './Collection.style';

import Facts from '../Facts';

import {
    ThumbsContainer, Thumb, ThumbInner, Img,
} from '../../../Collection/components/Section/Section.style';

import Switch from '../Switch';

import { useDropzone } from 'react-dropzone';

import { CancelButton, SaveButton } from '../Common/Button.style';
import { InputLabel, InputField } from '../Common/Input.style';
import { ActionsList } from '../Common/Lists.style';
import { ContentRow } from '../Common/Row.style';


import ReactDynamicImport from "react-dynamic-import";
const loader = () => import(`../Wysiwyg`);
const Editor = ReactDynamicImport({ loader });

const Collection = ({ collections, collection, itemIdx, collectionsCount, addNewCback, onDeleteCback }) => {
    const [showDetails, toggleDetails] = useState(false);

    const [image, setImage] = useState(collection.image || null);
    const [enabled, setEnabled] = useState(collection.enabled || false);
    const [name, setName] = useState(collection.name || '');
    const [startDate, setStartDate] = useState(collection.startDate || '');
    const [endDate, setEndDate] = useState(collection.endDate || '');
    const [facts, setFacts] = useState(collection.facts || []);

    const [imageOpacityState, setImageOpacityState] = useState(image ? 1 : 0);
    const { imageOpacity } = useSpring({
        // config: { mass: 1, tension: 170, friction: 70 },
        from: { imageOpacity: 0 },
        imageOpacity: imageOpacityState,
    });

    let contentState = EditorState.createEmpty();
    if (collection.description) {
        const rawContent = convertFromRaw(JSON.parse(collection.description.raw));
        contentState = EditorState.createWithContent(rawContent);
    }
    const [editorState, setEditorState] = useState(contentState);
    const [openSuccess, setOpenSuccess] = useState(false);


    const initialFiles = [];
    if (image) {
        initialFiles.push({
            name: image.public_id,
            preview: getImagePreview(image),
        });
    }
    const [files, setFiles] = useState(initialFiles);


    const db = useFirestore();

    const handleNewFact = () => {
        const newFacts = [...facts];
        newFacts.push({
            name: '',
            value: '',
        });
        setFacts(newFacts);
    };

    const handleDeleteFact = (index) => {
        const newFacts = [...facts];
        newFacts.splice(index, 1);
        setFacts(newFacts);
    };

    const handleChangeFact = (event, index, field) => {
        const newFacts = [...facts];
        newFacts[index][field] = event.target.value;
        setFacts(newFacts);
    };


    const handleChange = (event, type) => {
        switch(type) {
            case 'name':
                setName(event.target.value);
                break;
            case 'startDate':
                console.log(event.target.value);
                setStartDate(event.target.value);
                break;
            case 'endDate':
                setEndDate(event.target.value);
                break;
            case 'image':
                setImage(event);
                break;
            default:
                break;
        }
    };

    const onDelete = (event) => {
        event.preventDefault();
        const confirmed = confirm('Are you sure to do this?');
        if (confirmed) {
            handleDelete();
        }
    };

    const handleDelete = () => {
        if (collection.isNew) {
            onDeleteCback(collection);
        }
        else {
            db.collection('collections').doc(collection.id).delete().then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // setOpenSuccess(true);
        // return;

        const descriptionRaw = convertToRaw(editorState.getCurrentContent());

        const data = {
            enabled,
            name,
            startDate,
            endDate,
            image,
            facts,
            description: {
                raw: JSON.stringify(descriptionRaw),
                html: draftToHtml(descriptionRaw),
            },
        };

        const collectionRef = db.collection('collections');
        if (collection.isNew) {
            addNewCback(collection);

            collectionRef.add(data)
            .then(function(docRef) {
                console.log('Document successfully created');
            })
            .catch(function(error) {
                console.error('Error creating document: ', error);
            });
        }
        else {
            collectionRef.doc(collection.id).update(data)
            .then(function(docRef) {
                console.log('Document successfully updated');
                toggleDetails(false);
            })
            .catch(function(error) {
                console.error('Error updating document: ', error);
            });
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        // accept: 'image/*, video/*',
        onDrop: acceptedFiles => {
            const filteredFiles = [acceptedFiles[0]];
            setFiles(filteredFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

            const item = filteredFiles[0];

            const size = 1200;
            const quality = 0.87

            ImageTools.rotate(item, (blob, width, height) => {
                const newFile = Object.assign(new File([blob], `${item.name}`, {
                    type: item.type,
                }), {
                    tmpId: item.tmpId,
                });

                uploadFile({
                    file: newFile,
                    folder: `${name.toLowerCase()}`,
                    width,
                    height,
                    type: item.type,
                    stateChangeCback: (type, file, index, data) => {
                        switch (type) {
                            case UPLOAD_STATE.Progress:
                                setImageOpacityState(data.percent / 150.0);
                                break;
                            case UPLOAD_STATE.Done:
                                setImageOpacityState(1);
                            break;
                        }
                    },
                })
                .then(data => {
                    handleChange({
                        type: data.fileType,
                        publicId: data.public_id,
                        width: data.width,
                        height: data.height,
                    }, 'image');
                });
            }, item.type, quality, size);
        },
    });

    // const { getRootProps, getInputProps } = useDropzone({
    //     accept: 'image/*',
    //     onDrop: acceptedFiles => {
    //         const filteredFiles = [acceptedFiles[0]];
    //         setFiles(filteredFiles.map(file => Object.assign(file, {
    //             preview: URL.createObjectURL(file)
    //         })));

    //         filteredFiles.map(file => {
    //             uploadFile({
    //                 file,
    //                 stateChangeCback: (type, file, index, data) => {
    //                     switch (type) {
    //                         case UPLOAD_STATE.Progress:
    //                             setImageOpacityState(data.percent / 150.0);
    //                             break;
    //                         case UPLOAD_STATE.Done:
    //                             setImageOpacityState(1);
    //                             break;
    //                     }
    //                 },
    //             })
    //             .then(data => {
    //                 handleChange(data, 'image');
    //             });
    //         });
    //     },
    // });

    const thumbs = files.map(file => (
        <Thumb key={file.name} style={{
            opacity: imageOpacity,
        }}>
            <ThumbInner>
                <Img src={file.preview} />
            </ThumbInner>
        </Thumb>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    let background = '#fff';
    if (!enabled) {
        background = '#ffcccb';
    }
    if (collection.isNew) {
        background = '#ffffe0';
    }

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < theme.screenSize.mediumTablet;

    return (
        <TBody>
            {/* <TBodyTR> */}
            <TBodyTR onClick={() => toggleDetails(!showDetails)} background={background}>
                <TBodyTD>
                    <CellWrapper>
                        <LayoutRow>{collection.name}</LayoutRow>
                    </CellWrapper>
                </TBodyTD>
                {!isMobile && (
                    <TBodyTD>
                        <CellWrapper>
                            <EnabledLabel enabled={enabled}>{enabled ? 'Enabled' : 'Disabled'}</EnabledLabel>
                        </CellWrapper>
                    </TBodyTD>
                )}
                <TBodyTDActions isVisible={showDetails}>
                    <ActionsCellWrapper>
                        {!collection.isNew && (
                            <Fragment>
                                {itemIdx > 0 && <IconContainer onClick={(event) => handleMoveUp(event)}>
                                    <MoveUpIcon />
                                </IconContainer>}
                                {itemIdx < collectionsCount - 1 && <IconContainer onClick={(event) => handleMoveDown(event)}>
                                    <MoveDownIcon />
                                </IconContainer>}
                                <IconContainer>
                                    <Link to={`/collection/${collection.id}/`}>
                                        <EditIcon />
                                    </Link>
                                </IconContainer>
                            </Fragment>
                        )}
                        <IconContainer>
                            <DeleteIcon onClick={onDelete} />
                        </IconContainer>
                    </ActionsCellWrapper>
                </TBodyTDActions>
            </TBodyTR>
            {showDetails && (
                <DetailContentTR>
                    <DetailContentTD colSpan={isMobile ? 2 : 3}>
                        <DetailsContentWrapper>
                            {/* <Snackbar open={openSuccess} autoHideDuration={6000} onClose={setOpenSuccess}>
                                <Alert elevation={6} variant="filled" onClose={setOpenSuccess} severity="success">
                                    This is a success message!
                                </Alert>
                            </Snackbar> */}
                            <EditCard>
                                <Form onSubmit={handleSubmit}>
                                    <FieldSet>
                                        <ContentRow first right center>
                                            <Switch enabled={enabled} onToggle={setEnabled} />
                                            <SwitchLabel>Enable</SwitchLabel>
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Name</InputLabel>
                                            <InputField
                                                type="text"
                                                name="name"
                                                autocomplete="off"
                                                spellcheck="false"
                                                value={name}
                                                onChange={(event) => handleChange(event, 'name')}
                                            />
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Start Date</InputLabel>
                                            <InputField
                                                type="date"
                                                name="startDate"
                                                autocomplete="off"
                                                spellcheck="false"
                                                value={startDate}
                                                onChange={(event) => handleChange(event, 'startDate')}
                                            />
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>End Date</InputLabel>
                                            <InputField
                                                type="date"
                                                name="endDate"
                                                autocomplete="off"
                                                spellcheck="false"
                                                value={endDate}
                                                onChange={(event) => handleChange(event, 'endDate')}
                                            />
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Description</InputLabel>
                                            <Editor
                                                editorState={editorState}
                                                // wrapperClassName="demo-wrapper"
                                                // editorClassName="demo-editor"
                                                onStateChange={(state) => setEditorState(state)}
                                            />
                                        </ContentRow>
                                        <ContentRow>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop the image here, or click to select one</p>
                                            </div>
                                            <ThumbsContainer>
                                                {thumbs}
                                            </ThumbsContainer>
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Facts</InputLabel>
                                            <Facts
                                                facts={facts}
                                                addNewFact={handleNewFact}
                                                onDeleteFact={handleDeleteFact}
                                                onChangeFact={handleChangeFact}
                                            />
                                        </ContentRow>
                                        <ActionsList>
                                            <CancelButton type="button" onClick={() => toggleDetails(false)}>Cancel</CancelButton>
                                            <SaveButton type="submit">Save</SaveButton>
                                        </ActionsList>
                                    </FieldSet>
                                </Form>
                            </EditCard>
                        </DetailsContentWrapper>
                        <DetailsContentCard></DetailsContentCard>
                    </DetailContentTD>
                </DetailContentTR>
            )}
        </TBody>
    );
}

export default Collection;
